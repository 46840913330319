.listing {
    display: grid
    grid-template-columns: 1fr
    grid-gap: var(--padding-large)
}

@media (min-width: 1000px) {
    .listing {
        grid-template-columns: repeat( auto-fit, minmax(550px, 1fr) )
    }
}

.prediction {
    background: var(--background-color)
    border-radius: var(--padding-small)
    overflow: hidden
    padding: var(--padding-medium)
    box-shadow:
        inset 0 0 0 var(--shadow-height) rgba(255,255,255,.07),
        0 .5px 1px rgba(0,0,0,.1),
        0 2px 2px rgba(0,0,0,.1)
    transition: filter .2s ease-in-out, opacity .2s ease-in-out

    header {
        padding-bottom: var(--padding-medium)
        margin-bottom: var(--padding-medium)
        display: flex
        align-items: baseline
        gap: var(--padding-medium)
        border-bottom: var(--shadow-height) dashed var(--shadow-light-color)

        h4, .field {
            font-size: var(--head-font-size)
        }
        
        h4 {
            margin: 0
            flex: 1
            font-weight: 600

            .icon {
                display: inline-block
                vertical-align: text-top
                margin-right: var(--padding-small)
            }

            .destination {
                //box-shadow: inset 0 0 0 var(--shadow-height) var(--shadow-color)
                //margin-left: var(--padding-mini)
                padding: var(--padding-mini)// var(--padding-small)
                border-radius: var(--border-radius)
                color: currentColor
                overflow: hidden
                transition: background .1s ease-in-out

                &:hover {
                    background: var(--hover-background-color)
                }
            }
        }
    }

    summary {
        user-select: none
        padding: var(--padding-mini) var(--padding-small)
        border-radius: var(--border-radius)
        margin-top: var(--padding-medium)
        cursor: pointer
        font-weight: 500
        transition: background .1s ease-in-out, color .1s ease-in-out
        color: var(--secondary-text-color)

        &:hover {
            background: var(--primary-text-color)
            color: var(--background-color)
        }
    }

    ul {
        margin: 0
        padding-inline-start: 1em;
    }

    &[data-status='loading'], &[data-status='success'] {
        filter: grayscale(100%)
        opacity: .6
        pointer-events: none
    }

    &[data-status='success'] {
        opacity: .4
    }
}

.path {
    span {
        display: inline-block
        white-space: nowrap
        opacity: 0.6

        &:after {
            content: '/'
            margin: 0 4px
            font-weight: 300
        }

        &:last-child {
            opacity: 1

            &:after {
                content: ''
                margin: 0
            }
        }
    }
}

.raindrops {
    display: grid
    grid-gap: var(--padding-medium)
}

summary + .raindrops {
    margin-top: var(--padding-medium)
}

.raindropItem {
    position: relative
    display: grid
    grid-template-columns: auto 1fr auto
    grid-gap: var(--tertiary-font-size)
    align-items: center
    min-width: 0

    a {
        position: absolute
        top: 0; left: 0; bottom: 0; right: 0
        z-index: 1
    }

    .cover {
        position: relative

        img, &:before {
            border-radius: var(--border-radius)
            overflow: hidden
        }

        &:before {
            z-index: 1
            content: ''
            position: absolute
            top: 0; left: 0; right: 0; bottom: 0
            box-shadow: inset 0 0 0 var(--shadow-height) var(--shadow-color)
        }
    }

    .meta {
        max-width: 100%
        overflow: hidden
    }

    .title {
        font-weight: 500;
        font-size: var(--title-font-size);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: 0;
        max-width: 100%;
    }

    .info {
        color: var(--secondary-text-color)
        font-size: var(--secondary-font-size)
    }

    .remove {
        position: relative
        z-index: 2
        color: var(--secondary-text-color)
        opacity: 0
    }

    &:hover {
        .remove {
            opacity: 1
        }
    }
}

.colored {
    .accentButton {
        color: white
    }
}