.picture {
    margin: var(--padding-mini) 0
    box-shadow: inset 0 0 0 var(--shadow-height) var(--shadow-color)
    border-radius: var(--border-radius)
    overflow: hidden
    overflow: clip
    fill: var(--secondary-text-color)
    background: var(--background-color)

    &[data-active='true'] {
        fill: var(--accent-color)
        box-shadow: inset 0 0 0 1px var(--accent-color), 0 0 0 1px var(--accent-color)
    }
}

.item {
    cursor: pointer
}

.items {
    display: grid
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr))
    grid-gap: var(--padding-medium)
}