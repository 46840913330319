.text {
    padding-left: calc(var(--padding-mini) + var(--padding-small))
    position: relative

    white-space: pre
    white-space: pre-wrap

    &:before {
        content: ''
        position: absolute
        left: 0;top:3px;bottom:3px
        width: 3px
        border-radius: 3px
        background: var(--highlight-color, #ffee00)
        background-image: linear-gradient(to bottom, rgba(255,255,255,.3) 0, rgba(255,255,255,.3) 100%)
    }
}