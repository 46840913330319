.header:hover .rename, .rename:focus-visible,
.header:hover .open, .open:focus-visible {
    opacity: 1
}

.rename, .open {
    opacity: 0
    transition: .2s ease-in-out opacity;
    transition-delay: .1s

    &:focus-visible {
        transition: none
    }
}

html:global(:not(.extension)) .open {
    display: none
}