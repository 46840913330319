.multi {
    display: flex
    flex-wrap: wrap
    background: var(--alternative-background-color)
    border-radius: var(--border-radius)
    min-height: var(--button-height)
    line-height: 2
    transition: box-shadow 0.1s ease-in-out, background 0.1s ease-in-out

    &:focus-within {
        background: var(--background-color)
        box-shadow: inset 0 0 0 1px var(--accent-color), 0 0 0 var(--shadow-height) var(--accent-color)
    }

    &[data-disabled="true"] {
        pointer-events: none
        background: var(--disable-background-color)
    }
}

.token, .input {
    margin-left: var(--padding-small)
    height: var(--button-height)
}

.token {
    cursor: pointer
    padding: var(--padding-mini) 0

    .content {
        height: 100%
        display: flex
        align-items: center
        overflow: hidden
        overflow: clip
        border-radius: var(--button-height)
        color: var(--accent-color)
    }

    &:not(:hover) .close {
        display: none
    }

    &:hover {
        .icon {
            display: none
        }

        .content {
            color: var(--danger-color)
        }
    }
}

.input {
    line-height: var(--button-height)
    font-size: var(--primary-font-size)
    outline: none
    appearance: none
    border: 0
    padding: 0
    background: transparent
    flex: 1

    &[disabled] {
        color: var(--disable-text-color)
    }
}

.title {
    white-space: nowrap
    overflow: hidden
}