.selector {
    display: grid
    grid-template-columns: repeat(auto-fill, 1.6rem) //64
    grid-auto-rows: 1.2rem //48
    grid-gap: var(--padding-medium)
}

.theme {
    display: flex
    position: relative
    border-radius: var(--border-radius)
    overflow: hidden
    overflow: clip

    .sidebar {
        min-width: 0
        overflow: hidden
        overflow: clip
        flex: 1
        padding: .13rem .15rem
        background: var(--sidebar-background-color)
        font-family: monospace
        font-size: .15rem
        white-space: pre-wrap
        line-height: var(--padding-mini)
        color: var(--primary-text-color)
    }

    .main {
        flex: 2
        background: var(--background-color)
        padding: .15rem
        display: grid
        grid-template-columns: 1fr 1fr 1fr
        grid-template-rows: .15rem .15rem
        grid-gap: var(--padding-mini)

        span {
            background: var(--shadow-color)

            &:nth-child(odd) {
                opacity: .7
            }
        }
    }

    &:before {
        border-radius: var(--border-radius)
        content: ''
        position: absolute
        top: 0
        bottom: 0
        right: 0
        left: 0
        box-shadow: inset 0 0 0 var(--shadow-height) var(--shadow-color)
        transition: box-shadow .2s ease-in-out
    }

    &[data-active='true']:before {
        box-shadow: inset 0 0 0 2px var(--accent-color)
    }
}