//Actions
.actions {
    position: absolute
    z-index: 1
    right: var(--padding-medium)
    top: var(--padding-medium)
    display: inline-grid
    grid-auto-flow: column
    grid-gap: var(--padding-small)
    justify-content: start
    display: none
    //transition: opacity .1s linear

    > * {
        display: none
    }
}

for button in current_tab new_tab preview web copy important tags edit remove
    :global(.button-{button}) [data-button={button}] {
        display: grid
    }