.small[data-small="false"] {
    :global(.show-on-small-body) {
        display: none
    }
}

.small[data-small="true"] {
    :global(.hide-on-small-body) {
        display: none
    }
}