.button[data-init='true'] {
    pointer-events: none
}

.label {
    overflow: visible
}

html:global(.mobile) .label {
    display: none
}