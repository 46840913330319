.highlight {
    margin: var(--padding-mini) 0

    display: -webkit-box
    line-clamp: 2
    -webkit-line-clamp: 2
    -webkit-box-orient: vertical
    overflow: hidden
    overflow: clip

    .note {
        margin-right: var(--padding-small)
    }
}