.loaded {
    padding: var(--padding-mini) 0

    b {
        font-size: var(--title-font-size)
    }

    span {
        color: var(--secondary-text-color)
    }
}