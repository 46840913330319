.wrap {
    display: grid
    grid-auto-flow: column
    grid-gap: var(--padding-small)
    align-items: center
    justify-content: start
    height: var(--button-height)

    &[data-disabled='true'] {
        color: var(--disable-text-color)
    }
}