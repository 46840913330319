figure {
    margin: 0
}

progress::-webkit-progress-value {
    transition: width .3s ease-in-out;
}

html, body, :global(#react) {
    margin:0;
    width: 100%;
    height: 100%;
}

/* extension */
@media screen and (max-width: 800px) {
    html:global(.extension:not(.sidepanel):not(.mobile)),
    html:global(.extension:not(.sidepanel):not(.mobile)) body,
    html:global(.extension:not(.sidepanel):not(.mobile)) :global(#react) {
        width: fit-content;
        height: fit-content;
    }
}

[hidden] {
    display: none !important;
}