:global(.hide-tags) .tags {
    display: none
}

.tags {
    a {
        color: var(--accent-color)
        margin-right: var(--padding-small)
    }
    
    &:empty {
        display:none
    }
}