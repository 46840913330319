.empty {
    flex: 1
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    text-align: center
    padding: var(--padding-large)
}

.screenshot {
    border-radius: var(--border-radius);
    max-width: 100%;
    box-shadow: 0 0 0 var(--shadow-height) var(--shadow-color);
}