.default {
    .icon {
        color: var(--secondary-text-color)
    }
}

.enabled {
    font-weight: 600

    .dropbox {
        color: #0061FF !important
    }

    .gdrive {
        color: #FE2C25 !important
    }
}