.vendor {
    span {
        display: contents
        text-transform: capitalize
    }
}

.google {
    .icon {
        color: #FE2C25 !important
    }
}