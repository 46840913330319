.label {
    font-size: var(--secondary-font-size)
    color: var(--secondary-text-color)
    min-height: var(--button-height)
    display: flex
    align-items: center
    justify-content: space-between

    a {
        font-weight: 600
    }
}