.superLink {
    position: absolute
    left: 0
    right: 0
    bottom: 0
    top: 0
    
    color: transparent
    font-size: 0

    * {
        display: none
    }

    &:not(:focus-visible) {
        outline: none
    }
}