.switch {
	display: flex;
	margin: -5px
	
	a {
		flex: 1;
		width: 24px;
		height: 24px;
		border-radius: var(--border-radius)
		margin: 5px;
		box-shadow: inset 0px 0px 0px var(--shadow-height) var(--shadow-color)
		overflow: hidden
    	overflow: clip

		&.auto {
			background: var(--background-color)
			position: relative
			overflow: hidden
    		overflow: clip

			&:before {
				position: absolute
				left: 50%
				top: 0
				width: 100%
				height: 100%
				transform: skew(-45deg)
				content: ''
				background: linear-gradient(to bottom, var(--shadow-color) 0%, var(--shadow-light-color) 100%)
			}
		}

		&.day {
			background: white
		}
		
		&.sunset {
            background: #f8f2e3;
		}

		&.dark {
			background: darken(#383838,15%);
		}

		&.active {
			box-shadow: 0px 0px 0px 2px var(--accent-color)
		}
	}
}