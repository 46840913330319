.grid {
    --lazy-item-height: 300px

    main {
        display: grid;
        grid-template-columns: unquote('repeat(auto-fill, minmax(min(calc(50% - var(--padding-medium) * 2), var(--grid-item-width)), 1fr))')
        grid-template-rows: 1fr;
        grid-gap: var(--padding-medium)
        padding: var(--padding-medium)
        padding-bottom: 0
    }

    [data-lazy-item] {
        transition: background .2s ease-in-out;

        &:empty {
            box-shadow: inset 0 0 0 var(--shadow-height) var(--shadow-color)
            border-radius: var(--border-radius)
        }
    }
}