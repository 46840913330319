.page {
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
}

.center {
    text-align: center
    justify-content: center
}