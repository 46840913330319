.main {
    overflow: auto
}

.content {
    flex: 1
    display: flex
    flex-direction: column
    width: 16rem
    max-width: 100%
    margin: 0 auto

    padding: 0 var(--padding-small)
    padding-bottom: var(--padding-medium)
}