.wrap {
    display: flex
    flex-direction: row
    align-items: center
    min-height: var(--button-height)
    padding: 0 var(--padding-small)
    padding-right: var(--padding-mini)
    transition: box-shadow 0.1s ease-in-out, background 0.1s ease-in-out

    &[data-disabled="true"] {
        pointer-events: none
    }

    &:focus-within {
        transition: none
    }
}

.text {
    min-width: 0
    flex: 1
    font-size: var(--primary-font-size)
    line-height: var(--primary-line-height)
    outline: none
    appearance: none
    border: 0
    padding: 0
    resize: none
    background: transparent

    &[disabled] {
        color: var(--disable-text-color)
    }

    &[data-min-rows] {
        min-height: calc( var(--primary-font-size) * var(--primary-line-height) * var(--min-rows) + (var(--padding-small) * 2) )
    }

    &[data-max-rows] {
        max-height: calc( var(--primary-font-size) * var(--primary-line-height) * var(--max-rows) )
    }

    &[data-max-rows='1'] {
        mask-image: linear-gradient(to left, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 1) 20%)
        -webkit-mask-image: linear-gradient(to left, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 1) 15%)
    }
}

.icon {
    display: flex
    align-items: center
    color: var(--primary-text-color)
    margin-right: var(--padding-small)
}

.wrap[data-font='title'] {
    .text {
        font-size: var(--title-font-size)
        font-weight: 600
    }
}

.wrap[data-font='secondary'] {
    .text {
        color: var(--secondary-text-color)
    }
}

.wrap[data-variant='default'] {
    background: var(--alternative-background-color)
    border-radius: var(--border-radius)

    &[data-multiline], &[data-auto-size] {
        .text:not([data-max-rows='1']) {
            padding-top: var(--padding-small)
            padding-bottom: var(--padding-small)
        }
    }

    &:focus-within {
        background: var(--background-color)
        box-shadow: inset 0 0 0 1px var(--accent-color), 0 0 0 var(--shadow-height) var(--accent-color)
    }

    &[data-disabled="true"], &[data-readonly='true'] {
        background: var(--disable-background-color)
    }

    &[data-readonly='true'] {
        box-shadow: 0 0 0 var(--shadow-height) var(--shadow-color)
    }
}

.wrap[data-variant='less'] {
    padding: var(--padding-micro) 0
    min-height: 0

    .text {
        padding: 0
    }

    &:focus-within {
        box-shadow: 0 calc(1px + var(--shadow-height)) 0 var(--accent-color)
    }
}

.wrap[data-variant='inline'] {
    padding: 0

    .text {
        padding: 0
    }
}