@media screen and (max-width: 500px) {
    .close {
        display: none
    }
}

@media screen and (min-width: 500px) {
    .back {
        display: none
    }
}