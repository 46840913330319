:global(.svSidebar) {
    display: flex;
    flex-direction: column;
    --background-color: var(--sidebar-background-color);
    background-color: var(--background-color);
    position: relative;
    will-change: width;

    &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 10;
        width: 1px;
        box-shadow: inset calc(var(--shadow-height) * -1) 0 0 var(--shadow-color);
    }
}

:global(.svSidebarBackdrop) {
    background: rgba(0,0,0,.25);
}