.path {
    direction: rtl
    text-align: left
    display: block
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    word-wrap: break-word
}

.parents {
    color: var(--secondary-text-color)
}