.wrap {
    justify-self: end
    align-self: start
}

.cover {
    display: block
    position: relative
    overflow: hidden
    overflow: clip
    color: unquote('hsla(var(--background-hsl), .8)')
    margin-top: 2px
    border-radius: var(--border-radius)

    * {
        pointer-events: none
    }

    &:before {
        position: absolute
        left:0; top: 0; bottom: 0; right: 0;
        content: ''
        box-shadow: inset 0 0 0 var(--shadow-height) var(--shadow-color)
        border-radius: var(--border-radius)
        z-index: 1
    }

    &:focus-visible {
        outline: none
        box-shadow: 0 0 0 2px var(--background-color), 0 0 0 calc(var(--shadow-height) + 3px) var(--accent-color)
    }
}

.more {
    position: absolute
    bottom: 0
    right: 0
    background: var(--accent-color, colorTheme)
    border-top-left-radius: calc(var(--border-radius) * 2)
    border-bottom-right-radius: var(--border-radius)
    width: var(--icon-size)
    height: var(--icon-size)
}

@supports (backdrop-filter: blur(20px)) {
	.more {
		background: unquote('hsla(var(--accent-hsl),.85)')
		backdrop-filter: blur(10px)
	}
}