.intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    text-align: center;
    padding: var(--padding-large)
    gap: var(--padding-medium)
}

.headline {
    font-size: var(--head-font-size)
    font-weight: bold
}

.sub {
    color: var(--secondary-text-color)
}

.headline, .sub {
    max-width: 600px
}