.item {
    display: grid
    grid-auto-flow: row
    grid-row-gap: var(--padding-small)
    padding: var(--padding-medium)
    transition: .1s ease-in-out background
    cursor: default

    &:focus-within {
        background: var(--sidebar-background-color)

        .text {
            color: var(--secondary-text-color)
        }
    }
}

.text {
    transition: .1s ease-in-out color

    &[data-clickable='true'] {
        cursor: pointer
    }
}

.noteWrap {
    flex: 1
    
    .note {
        min-height: auto
    }
}

.created {
    font-size: var(--tertiary-font-size)
    color: var(--secondary-text-color)
    pointer-events: none
}

.footer {
    display: flex
    flex-direction: row
    align-items: flex-end
}

.buttons {
    display: flex
    align-items: center
    gap: var(--padding-small)
    position: relative
    margin: calc(var(--padding-mini) * -1) 0

    .created {
        opacity: 0
        position: absolute
        right: 0
    }
}

/* Desktop specific behaviour */
@media (pointer: fine) {
    .item {
        .note {
            ::placeholder {
                transition: .1s ease-in-out color
                color: transparent
            }
        }

        &:hover, &:focus-within {
            .buttons > * {
                opacity: 1
            }

            .buttons .created {
                opacity: 0
            }

            .note ::placeholder {
                color: var(--disable-text-color)
            }
        }
    }

    .buttons > * {
        opacity: 0
        transition: .1s ease-in-out opacity
    }

    .buttons .created {
        opacity: 1
    }
}