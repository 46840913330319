:global(.svReader) {
    contain: strict
    
    background-color: var(--background-color)
    overflow: auto
    overflow: overlay
    position: relative

    display: flex
    flex-direction: column
}