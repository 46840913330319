.article, .embed {
    display: block
    width: 100%
    height: 100%
    border: 0
    box-shadow: none
    background: transparent
}

.preloader {
    position: absolute
    left: 0
    right: 0
    top: auto !important;
    display: flex
    align-items: center
    justify-content: center
    pointer-events: none
    animation: fadeIn .4s ease-in-out
}

@keyframes fadeIn {
    0% {opacity: 0}
    100% {opacity: 1}
}