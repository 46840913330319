.cache {
    height: 100%
    display: flex
    flex-direction: column
}

.frame {
    flex: 1
    border: 0
}

.status {
    &[data-status='ready'] .icon {
        color: #00A51B
    }

    &[data-status='retry'] .icon {
        color: var(--accent-color)
    }

    &[data-status='failed'], &[data-status^='invalid-'] {
        .icon {
            color: #DC5758
        }
    }
}

.icon {
    margin-right: 8px;
}