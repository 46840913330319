.tabs {
	display: inline-flex

	> :not(:last-child) {
		margin-right: var(--padding-small)
	}
}

.item {
	position: relative
}