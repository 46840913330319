.default {
    .icon {
        color: var(--secondary-text-color)
    }
}

.enabled {
    font-weight: 600

    .google {
        color: #FE2C25 !important
    }
}