.upgrade {
    box-shadow: 0 calc(var(--shadow-height) * -1) 0 var(--shadow-color)
    font-size: var(--secondary-font-size)
    color: var(--primary-text-color)
    padding: calc(var(--padding-small) + var(--padding-mini)) var(--padding-medium)
    display: flex
    align-items: center
    overflow: hidden
    overflow: clip
    position: relative
    z-index: 1

    @media (pointer: fine) {
        &:hover {
            background: var(--hover-background-color)
        }
    }
}

.pic {
    position: absolute
    right: 16px
    transform: rotate(15deg)
}

.title {
    display: block
    flex: 1
    margin-right: 85px

    .headline {
        display: block
        font-weight: 600
    }
}

html:global(.mobile) .headline {
    display: none
}