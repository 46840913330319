.content {
    flex: 1
    min-height: 0
    overflow-x: clip
    overflow-y: auto
    overscroll-behavior: contain
    position: relative

    &[data-indent] {
        padding: var(--padding-medium)
        padding-top: 0
    }
}