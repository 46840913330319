.path {
    display: inline-flex
    align-items: center
    justify-content: center
    font-weight: 500
    position: relative
    z-index: 1

    &:hover {
        text-decoration: underline
    }
}

a.path,
.path {
    color: inherit    
}

.icon {
    width: var(--padding-medium)
    height: var(--padding-medium)
    margin-right: var(--padding-small)
}