.preloader {
	color: var(--accent-color)
	position: relative

	.bg {
		opacity: 0.35
	}

	.main {
		animation: rotate .6s ease-in-out infinite
		position: absolute
		top: 0
		right: 0
		bottom: 0
		left: 0
	}
}

@keyframes rotate {
	0% {
		transform: rotate(0)
	}
	12.5% {
		transform: rotate(0)
	}
	12.500001% {
		transform: rotate(45deg)
	}
	25% {
		transform: rotate(45deg)
	}
	25.00001% {
		transform: rotate(90deg)
	}
	37.5% {
		transform: rotate(90deg)
	}
	37.500001% {
		transform: rotate(135deg)
	}
	50% {
		transform: rotate(135deg)
	}
	50.00001% {
		transform: rotate(180deg)
	}
	62.5% {
		transform: rotate(180deg)
	}
	62.500001% {
		transform: rotate(225deg)
	}
	75% {
		transform: rotate(225deg)
	}
	75.00001% {
		transform: rotate(270deg)
	}
	87.5% {
		transform: rotate(270deg)
	}
	87.500001% {
		transform: rotate(315deg)
	}
	100% {
		transform: rotate(315deg)
	}
}