.help {
    height: auto
    margin-top: var(--padding-small)
    padding: var(--padding-small) var(--padding-medium)
    padding-right: var(--padding-small)
}

.tip {
    flex: initial
    max-width: 6rem
    white-space: initial
}

.actions {
    flex: 1
    display: block
    text-align: right 
}