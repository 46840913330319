.wrap {
    z-index: 1
    overflow-y: auto
    max-height: 50vh
    box-shadow: 0 calc(var(--shadow-height) * -1) 0 var(--shadow-color)
}

.items {
    max-width: 16rem
    margin: 0 auto
    padding: var(--padding-medium)
    padding-top: 1px
}